import React from "react";

import Layout from "../../components/Layout";
import Seo from "../../components/SEO";

const TermsPage = () => (
  <Layout>
    <Seo title="Terms" />
    <div className="legalContent">
      <h1 id="top"> TERMS AND CONDITIONS</h1>
      <p>
        <strong>Last Update: March 3, 2021</strong>
      </p>
      <p>
        Phoenician Trading Company, LLC d/b/a Supply Caravan (“
        <strong>Supply Caravan</strong>,” “<strong>we</strong>,” “
        <strong>us</strong>,” or “<strong>our</strong>”) owns or licenses all
        right, title and interest in and to the mobile-enabled website located at{' '}
        <a href="https://www.supplycaravan.com">www.supplycaravan.com</a> (the “
        <strong>Site</strong>”). We welcome you to view and use the functionality,
        applications and services available on or through the Site (the “
        <strong>Services</strong>”) and wish to inform you about important terms
        which apply while doing so. We have established the following terms and
        conditions, together with our{' '}
        <a href="https://www.supplycaravan.com/privacy">Privacy Policy</a>{' '}
        (collectively, the “<strong>Agreement</strong>”) with which you must
        comply when you access, view and use the Site, Marketplace and/or Services
        offered by Supply Caravan.
      </p>
      <p>The following defined terms are used in this Agreement:</p>
      <ul>
        <li>
          “<strong>Buyer</strong>” means anyone else who visits the Site to
          purchase Goods.
        </li>
        <li>
          “<strong>Carrier</strong>” means a commercial provider of transportation
          who visits the Site to bid on the transportation of Goods exchanged by
          Buyers and Sellers.
        </li>
        <li>
          “<strong>Goods</strong>” refers to wholesale items being purchased, sold
          or lined up for transport through the Site.
        </li>
        <li>
          “<strong>Marketplace</strong>” means the online marketplace available at
          the Site for the sale of Goods.
        </li>
        <li>
          “<strong>Seller</strong>” means an individual or company who visits our
          Site in order to sell or act as a distributor of Goods.
        </li>
        <li>
          “<strong>Users</strong>,” “<strong>you</strong>” or “
          <strong>your</strong>” refer to the people and/or entities visiting the
          Site or registered to use the Services as Buyers, Sellers and Carriers.
        </li>
        <li>
          “<strong>Transaction</strong>” refers to a commercial arrangement
          between Users facilitated through the Site.
        </li>
      </ul>
      <p>
        BY ACCESSING AND VIEWING THE SITE, USERS AGREE TO THE TERMS AND CONDITIONS
        CONTAINED IN THIS AGREEMENT AND IN OUR{' '}
        <a href="https://www.supplycaravan.com/privacy">PRIVACY POLICY</a>, WHICH
        IS INCORPORATED HEREIN BY REFERENCE. YOU AFFIRM THAT YOU ARE EIGHTEEN (18)
        YEARS OF AGE AND FULLY ABLE AND COMPETENT TO ENTER INTO THE TERMS,
        CONDITIONS, OBLIGATIONS, AFFIRMATIONS, REPRESENTATIONS AND WARRANTIES SET
        FORTH IN THIS AGREEMENT, AND TO ABIDE BY AND COMPLY WITH THIS AGREEMENT,
        AND YOU HEREBY ACCEPT THIS AGREEMENT WITHOUT LIMITATION OR QUALIFICATION.
        IF YOU DO NOT ACCEPT THE TERMS AND CONDITIONS OF THIS AGREEMENT, DO NOT
        ACCESS OR VIEW THE SITE OR USE THE SERVICES.
      </p>
      <h2 id="i"> I. Amendments to this Agreement</h2>
      <p>
        This Agreement may be amended from time to time, with or without notice to
        Users. Supply Caravan recommends that you revisit this page frequently, so
        you are aware of any changes. As appropriate, Supply Caravan may provide
        you additional notice of any material amendment. Your continued use of the
        Site and Services will be deemed your agreement with the new policy. If
        you do not agree with the changes, then you should stop using the Site and
        Services immediately.
      </p>
      <h2 id="ii"> II. Ownership of Intellectual Property</h2>
      <p>
        A. With the exception of User Information (as defined below), Supply
        Caravan and its licensors own all information, text, reports, analyses,
        data, graphics, files, documents, images, sound recordings, audio and
        visual clips, interactive features, photographs, programs, applications,
        software, scripts and any other content that you may access and view on
        the Site, as well as the collection, design, production, selection,
        layout, function, arrangement and “look and feel” thereof (collectively,
        the “<strong>Supply Caravan Material</strong>”). We may incorporate third
        party software as part of the Site, and all such third party software is
        subject to additional terms provided by the third party licensor. The
        names, trademarks, logos, slogans and taglines appearing on the Site
        (collectively, the “<strong>Trademarks</strong>”) are owned by or licensed
        to Supply Caravan and, if licensed, are used with permission of the owner.
        The Trademarks and Supply Caravan Material are protected by trademark,
        copyright and other intellectual property laws. Supply Caravan requires
        you to respect the intellectual property rights that we and our third
        party licensors have in the Supply Caravan Material and in the Trademarks,
        as the case may be.
      </p>
      <p>
        B. You acknowledge and agree that any questions, comments, ideas, feedback
        or other information provided by you to Supply Caravan (“
        <strong>Feedback</strong>”) are not confidential and that Supply Caravan
        may reproduce, display, perform, distribute, publish, modify, edit or
        otherwise use such Feedback as it deems appropriate, for any and all
        commercial or non-commercial purposes, in its sole discretion. If you
        suggest any new features and/or functionality for the Site that Supply
        Caravan subsequently incorporates into the Site (or any other website,
        product or service of Supply Caravan), you further acknowledge that (i)
        Supply Caravan shall own, and has all rights to use, such suggestions, and
        the Site (or any other website, product or service of Supply Caravan)
        incorporating such new features and/or functionality shall be the sole and
        exclusive property of Supply Caravan and (ii) all such suggestions shall
        be free from any confidentiality restrictions that might otherwise be
        imposed upon Supply Caravan.
      </p>
      <h2 id="iii"> III. Your License</h2>
      <p>
        Supply Caravan hereby grants you a limited, non-exclusive, revocable,
        non-transferable license to access and view the Site and Marketplace in
        accordance with the terms of this Agreement. This license does not give
        you any ownership or intellectual property interest in any Supply Caravan
        Material or the Trademarks. Other than as required to facilitate your
        permissible use of the Site and Services, you may not reproduce, perform,
        publicly display, embed, create derivative works of, republish, upload,
        post, retransmit or redistribute in any way whatsoever any Supply Caravan
        Material or the Trademarks. All rights not expressly granted to you under
        this Agreement are reserved by Supply Caravan.
      </p>
      <h2 id="iv"> IV. User Information</h2>
      <p>
        You grant to Supply Caravan a royalty free, perpetual, worldwide,
        non-exclusive, transferable and sublicensable license to any and all
        content posted by you on the Site, including any text, photographs, videos
        and other information posted, sent or entered by you while accessing the
        Site (“<strong>User Information</strong>”) to use, reproduce, copy, adapt,
        modify, merge, distribute, publicly display, create derivative works from,
        and incorporate such User Information into other works in order to provide
        you with the Services and operate our business. You acknowledge that
        Supply Caravan exercises no control whatsoever over the content of the
        User Information and it is your sole responsibility, at your own expense,
        to provide the information, and to ensure that the information you
        transmit or receive complies with all applicable laws and regulations now
        in place or enacted in the future. Furthermore, Supply Caravan is under no
        obligation to review User Information for accuracy, potential liability,
        or for any other reason. If we do, Supply Caravan may remove any User
        Information which it finds violates this Agreement or other Supply Caravan
        policies.
      </p>
      <p>
        WHEN SUBMITTING USER INFORMATION, YOU ACKNOWLEDGE AND AGREE THAT IT MAY BE
        MADE AVAILABLE TO THE PUBLIC, INCLUDING BUT NOT LIMITED TO OTHER USERS OF
        THE SITE, AND IT IS POSSIBLE THAT THIRD PARTIES MAY COLLECT USER
        INFORMATION AVAILABLE ON THE SITE FOR THEIR OWN MARKETING AND COMMERCIAL
        PURPOSES. SUPPLY CARAVAN SHALL NOT BE RESPONSIBLE FOR THE CONTENT OR
        ACCURACY OF USER INFORMATION AND DISCLAIMS ALL WARRANTIES, EXPRESS AND
        IMPLIED, WITH RESPECT TO USER INFORMATION, INCLUDING, BUT NOT LIMITED TO,
        WARRANTIES OF ACCURACY, QUALITY OF INFORMATION, FITNESS FOR A PARTICULAR
        PURPOSE, OR NON-INFRINGEMENT. You represent that you own or have secured
        all legal rights necessary for the User Information submitted by you to be
        used by you, Supply Caravan, and others as described and otherwise
        contemplated in this Agreement. You represent and warrant that each person
        identified, depicted or shown in the User Information, if any (and if a
        minor, the parent or guardian of the minor) has provided consent to the
        use of the User Information consistent with this Agreement.
      </p>
      <h2 id="v"> V. Reviews &amp; Ratings</h2>
      <p>
        Users may be permitted to submit reviews and ratings about Goods and/or
        other parties involved in their Transactions through the Site, including
        other Buyers, Sellers and Carriers. You are solely responsible for your
        reviews and ratings, and Supply Caravan may, in its sole discretion,
        choose to remove or not to remove reviews and ratings once published. You
        will not submit any reviews that may be considered by Supply Caravan to be
        infringing, harassing, libelous, abusive, threatening, obscene, profane,
        hateful, offensive, harmful, vulgar, distasteful, defamatory or otherwise
        violates any relevant law or right of any other party, or that is
        racially, ethnically or otherwise objectionable. All of your reviews and
        ratings will be based upon your actual first-hand experiences. All of your
        reviews and ratings must be accurate, honest, truthful and complete in all
        respects. You acknowledge and agree that you do not work for, own any
        interest in or serve on the board of directors of, any of the Users for
        which you submit reviews and ratings, and that you have not received any
        form of compensation to post reviews and ratings. You will not submit
        reviews that comment on the reviews of other Users, and you will not
        submit reviews with hyperlinks.
      </p>
      <p>
        Reviews and ratings provided by Users do not necessarily reflect the views
        of Supply Caravan, its officers, managers, owners, employees, agents or
        designees.
      </p>
      <h2 id="vi"> VI. Account Credentials</h2>
      <p>
        As a registered User of the Site, your “
        <strong>Account Credentials</strong>” include the following information:
      </p>
      <p>
        A. <u>Supply Caravan Account</u>. You will be asked to register for a
        customer account with Supply Caravan (a “
        <strong>Supply Caravan Account</strong>”) in order to access marketplace
        listings available at the Site and interact with other Users of the Site.
        You will be asked to provide certain personal information when registering
        for a Supply Caravan Account as more fully described in our{' '}
        <a href="https://www.supplycaravan.com/privacy">Privacy Policy</a>.
      </p>
      <p>
        B. <u>Usernames and Passwords</u>. The usernames and passwords you create
        when establishing a Supply Caravan Account are a part of your Account
        Credentials.
      </p>
      <p>
        You shall be solely responsible for ensuring the security and
        confidentiality of your Account Credentials. You acknowledge that you will
        be fully responsible for all liabilities incurred through the
        inappropriate use of any of your Account Credentials and that any
        transactions under your Supply Caravan Account will be deemed to have been
        performed by you. Use or disclosure of your Account Credentials, other
        than as provided in this Agreement, shall be considered a breach of this
        Agreement by you. You may not share your Account Credentials with any
        other User of the Site.
      </p>
      <p>
        As a condition of your use of the Site, you agree to (i) provide Supply
        Caravan with true, accurate, current and complete information as prompted
        by the Site when registering for or using the Site and Services, (ii)
        update and maintain the truthfulness, accuracy and completeness of such
        information within your Supply Caravan Account, and (iii) comply with all
        of Supply Caravan’s rules and regulations and security restrictions in
        connection with use of the Site.
      </p>
      <h2 id="vii"> VII. Listings</h2>
      <p>
        Users may post listings in the Marketplace (each, a “
        <strong>Listing</strong>”) to request or advertise Goods or to request
        transportation for Goods. The following provides more detail regarding the
        three types of Listings currently available through the Marketplace:
      </p>
      <p>
        A. For Sale Listings: Posted by Sellers with Goods available for purchase.
        Sellers may post For Sale Listings for two broad categories of Goods:
      </p>
      <ul>
        <li>
          <p>
            <em>Bulk Sale Goods</em>: Offered at a negotiable price for bulk
            commoditized Goods or for certain types of business-to-business “lot”
            sale transactions. A For Sale Listing for Bulk Sale Goods must include
            the following information:
          </p>
          <ul>
            <li>Type of product;</li>
            <li>Date available;</li>
            <li>Price per unit;</li>
            <li>Number of units;</li>
            <li>Quantity;</li>
            <li>Delivery options;</li>
            <li>Description (free text input);</li>
            <li>Pictures;</li>
            <li>
              Tags for Goods that satisfy applicable criteria and standards (“
              <strong>Tags</strong>”), such as Organic, Free Range, etc.; and
            </li>
            <li>Proposed Transaction Terms</li>
          </ul>
        </li>
        <li>
          <p>
            <em>Wholesale Goods</em>: Offered by Sellers at a fixed or estimated
            price principally for recurring sales of Goods. A For Sale Listing for
            Wholesale Goods must include the following information:
          </p>
          <ul>
            <li>Type of product;</li>
            <li>UPC (optional);</li>
            <li>Wholesale price;</li>
            <li>MSRP (optional);</li>
            <li>Units per wholesale case/ box;</li>
            <li>Delivery options;</li>
            <li>Description (free text input);</li>
            <li>Picture(s);</li>
            <li>Tags; and</li>
            <li>
              Proposed transaction terms (including actual or estimated price).
              Note that Sellers must select a checkbox in a Listing to indicate
              that the price offered is an estimate.
            </li>
          </ul>
        </li>
      </ul>
      <p>
        B. Wanted Listing or Request for Offer: A Wanted Listing (which is also
        referred to by us and the Site as a Request for Offer) is a request for
        specific types and/or quantities of Goods at a negotiable price. A Wanted
        Listing or Request for Offer will include the following:
      </p>
      <ul>
        <li>Type of product;</li>
        <li>Date desired;</li>
        <li>Offered Price per Unit;</li>
        <li>Units;</li>
        <li>Quantity;</li>
        <li>Tags/ Labels/ Attributes;</li>
        <li>Proposed transaction terms.</li>
      </ul>
      <p>
        C. Transport Wanted Listing: Posted by a Buyer, Seller or other User with
        shipping needs to request the transport of Goods.
      </p>
      <ul>
        <li>Type of product;</li>
        <li>Units;</li>
        <li>Quantity;</li>
        <li>Date/ Time available for pick-up;</li>
        <li>Move-by date/ time;</li>
        <li>Deliver-by date/ time;</li>
        <li>Pick-up address;</li>
        <li>Delivery address;</li>
        <li>Packaging/ storage requirements (free text input);</li>
        <li>Notes (free text input); and</li>
        <li>Proposed transaction terms.</li>
      </ul>
      <p>
        Please note that Supply Caravan reserves the unequivocal right to decide
        whether, where and how Listings and User Information submitted to the Site
        are displayed on the Site. Users may not claim any association with Supply
        Caravan without our explicit written permission.
      </p>
      <h2 id="viii"> VIII. Offers</h2>
      <p>
        In response to a Listing, Users may submit one of three kinds of “
        <strong>Offers</strong>”:
      </p>
      <ol>
        <li>
          <p>
            <em>Purchase Offer</em>: Made by a Buyer in response to a For Sale
            Listing posted by a Seller.
          </p>
        </li>
        <li>
          <p>
            <em>Fulfillment Offer</em>: Made by a Seller in response to a Wanted
            Listing / Request for Offer made by a Buyer.
          </p>
        </li>
        <li>
          <p>
            <em>Transport Offer</em>: Made by a Carrier, freight broker and/or
            third party logistics provider in response to a Transport Wanted
            Listing.
          </p>
        </li>
      </ol>
      <p>
        If you upload a Listing to the Site or otherwise submit User Information
        to Supply Caravan, you warrant that: (i) if you are a User, you have the
        full right to buy, sell and/or transport Goods in accordance with the
        Terms negotiated through the Site; (ii) all Listings (and Goods listed)
        conform to applicable laws and regulations; (iii) you have the lawful
        right to distribute and reproduce any content in a Listing and it does not
        infringe on any trade secret, patent, trademark, copyright, and/or other
        proprietary or intellectual right of any party whatsoever; and (iv) all
        Listings are true and correct and not misleading or deceptive. You agree
        that Supply Caravan can contact you and you will provide us with accurate
        information or clarification of matters related to material uploaded by
        you in a Listing or otherwise.
      </p>
      <p>
        If any content uploaded in a Listing is inaccurate and/or untrue, the User
        responsible for the inaccurate or untrue content must immediately attempt
        to delete or edit the content and/or contact Supply Caravan for
        assistance. The User acknowledges and agrees that Supply Caravan shall not
        be liable to the User for any loss that the User may suffer as a result of
        the incorrect content.
      </p>
      <h2 id="ix"> IX. Negotiations and Other Rules for Orders</h2>
      <p>
        Users may use the Site to negotiate the terms for the purchase, sale
        and/or transport of Goods as follows:
      </p>
      <p>
        A. <u>Counteroffers</u>. In response to an Offer, Users may propose
        alternative terms for quantity, price, transportation, payment and/or
        other commercial terms specified in the Offer (a “
        <strong>Counteroffer</strong>”). The party making the original Offer will
        have the opportunity to accept or reject the Counteroffer; and, if
        rejected, both parties may continue to submit Counteroffers to the other
        party until an agreement is reached or the negotiation is abandoned by one
        or both parties. For the avoidance of doubt, a Counteroffer constitutes an
        offer to buy or sell the Goods on those terms suggested in the
        Counteroffer.
      </p>
      <p>
        B. <u>Purchase Orders and Shipping Orders</u>.
      </p>
      <ol>
        <li>
          <p>
            <em>Purchase Orders</em>. If and when an Offer or a Counteroffer is
            accepted by a party and/or a party clicks “<strong>Accept</strong>” or
            “<strong>Place Order</strong>” to purchase Goods, a purchase order (“
            <strong>Purchase Order</strong>”) will be generated with the agreed
            upon terms (the “<strong>Terms</strong>”) and the Purchase Order will
            be made available in each party’s Supply Caravan Account.
          </p>
        </li>
        <li>
          <p>
            <em>Shipping Orders</em>. If and when Users arrange transportation for
            Goods through the Site, a shipping order (“
            <strong>Shipping Order</strong>”) will be generated with the agreed
            upon Terms and made available in each party’s Supply Caravan Account.
          </p>
        </li>
      </ol>
      <p>
        Supply Caravan does not and cannot warrant that Terms for any Transaction
        are complete, reliable, accurate or appropriate for individual
        circumstances. You agree that you enter into any Transaction on Terms
        negotiated through the Site relying entirely upon your own independent
        assessment of all relevant matters and do not rely upon any warranty,
        statement or representation made or given by Supply Caravan or on our
        behalf.
      </p>
      <p>
        C. <u>Fulfillment</u>. Once Users agree on Terms and a Purchase Order
        and/or Shipping Order is/are generated for the Transaction, Users
        acknowledge that each party has entered into a binding agreement to
        perform its obligations with respect to the Transaction in accordance with
        the Terms. The parties must schedule a date for fulfillment of the
        Transaction (“<strong>Fulfillment</strong>”), on which date Sellers and
        Carries parties must perform their obligations for the Transaction in
        accordance with the applicable Terms unless otherwise agreed by the
        parties. Once Fulfillment occurs, the Seller and/or Carriers must indicate
        through the Site that the Transaction has been “
        <strong>Fulfilled,</strong>” at which time an invoice will be generated
        and sent to the counterparty.{' '}
        <b>
          For Purchase Orders subject to <u>estimated pricing</u> as indicated
          during the ordering process, Seller will have the opportunity to edit
          the Purchase Order to reflect actual pricing before selecting Fulfilled.
        </b>{' '}
        The party receiving the invoice will have the opportunity to confirm that
        Fulfillment was completed or to raise a good faith dispute with respect to
        the Transaction before payment is initiated through the Site.
      </p>
      <p>
        D. <u>Payment</u>. Once a User who purchases Goods or arranges
        transportation confirms that Fulfillment has occurred in accordance with
        the Terms for the Transaction, the User will then be asked to confirm that
        payment for the Goods or transportation services should be initiated
        through the Site. The User will be presented with a range of payment
        options for making payments through the Site, which are described in more
        detail in <a href="#xiii">Section XIII</a> below and in our{' '}
        <a href="https://www.supplycaravan.com/privacy">Privacy Policy</a>.{' '}
        <strong>
          Please note that we collect all fees due and owing to Supply Caravan in
          connection with a Transaction at the time when payment is initiated
          through the Site for the Transaction.
        </strong>
      </p>
      <p>
        E. <u>Refund Policy</u>. The contracting process facilitated by the Site
        is designed to avoid disputes between the parties after payment for Goods
        or transportation services has been completed by requiring both parties to
        confirm Fulfillment occurred in accordance with the Terms before payment
        is initiated. The parties have an opportunity to raise good faith disputes
        with respect to Goods delivered or transportation services performed using
        a dispute resolution process through the Site. In the event that a dispute
        relating to a Transaction arises after Fulfillment occurs and payment is
        completed through the Site, any refund, credit and/or other arrangements
        are at the discretion of the contracting parties and may not be initiated
        through the Site without prior approval from Supply Caravan. For the
        avoidance of doubt, all fees paid to Supply Caravan in connection with a
        transaction are non-refundable.
      </p>
      <p>
        F. <u>Contracting Rules</u>: The following rules are applicable to Users
        when negotiating Transactions through the Site:
      </p>
      <ul>
        <li>
          Users may retract an Offer or Counteroffer at any time before it is
          accepted, rejected or Counteroffered by another party.
        </li>
        <li>
          There is no limit to the number of Offers or Counteroffers that can be
          made with respect to a Listing, and Supply Caravan typically does not
          limit the number of interactions initiated by a User through the Site.
        </li>
        <li>
          The Site will store a record of the Terms applicable to a Transaction,
          subject to certain disclaimers and releases to acknowledge that Supply
          Caravan is not in any way a party to contracts between Users who decide
          to enter into a Transaction facilitated through the Site.{' '}
          <strong>
            Supply Caravan strongly urges all Users to seek independent legal
            counsel in connection with its commercial activities through the Site.
          </strong>
        </li>
        <li>
          Users who connect through the Site regarding a Transaction may not seek
          to interact outside of the Site regarding the same or similar
          transaction(s) involving the Goods or transport of Goods.
        </li>
      </ul>
      <p>
        G.{' '}
        <u>
          Failed Payments and Illegal, Fraudulent, Deceptive and Suspicious
          Activity
        </u>
        : Users are responsible for all payment activity initiated through the
        Site and for any of their illegal, fraudulent, deceptive and/or suspicious
        activities while using the Marketplace. Supply Caravan may take the
        following actions in the event of a User’s failed payment or any known or
        suspected illegal, fraudulent, deceptive and/or suspicious activity:
        <p>
          <i>Failed Payments</i>:
        </p>
        <ol>
          <li>
            <u>Notification</u>. A User will be immediately notified of its failed
            payment for a pending transaction by automated message generated by
            the Platform. The intended recipient of funds (e.g., Seller/Carrier)
            is also notified of failed payment.
          </li>
          <li>
            <u>Cure Period</u>. A User who initiated a failed payment will be
            provided five (5) business days to address reason(s) for failed
            payment (e.g., insufficient funds in bank account) and to resubmit
            payment through the Site to complete its pending transaction.
          </li>
          <li>
            <u>Reasonable Investigation</u>. If User does not timely resubmit
            payment to complete a pending transaction, Supply Caravan will
            promptly conduct a reasonable investigation to determine the reason
            for User’s failed payment and status of User’s actions to remedy
            issues and resubmit payment.
          </li>
          <li>
            <u>Initial Suspension</u>. Supply Caravan may suspend a User’s access
            to the Site and/or ability to complete additional Transactions through
            the Site until User satisfies outstanding payment(s) due and payable
            to other Users of the Site.
          </li>
          <li>
            <u>Permanent Suspension / Account Termination</u>. If a User fails to
            remedy outstanding failed payment(s), Supply Caravan may permanently
            suspend and/or terminate the User’s Supply Caravan Account, notify
            appropriate law enforcement, and/or pursue any other remedies
            available at law or equity.
          </li>
          <li>
            <u>Notification to Dwolla</u>. If User submitted a failed payment
            using the Site’s ACH payment functionality powered by Dwolla, Inc. (“
            <b>Dwolla</b>”), Supply Caravan will notify Dwolla in the event of a
            User’s refusal or inability to remedy a failed payment
          </li>
        </ol>
        <p>
          <i>Fraudulent Activity</i>:
        </p>
        <ol>
          <li>
            <u>Monitoring</u>. Supply Caravan monitors use of the Site and
            Marketplace for illegal, fraudulent, deceptive and suspicious
            activity.
          </li>
          <li>
            <u>Preliminary Suspension</u>. If Supply Caravan detects potentially
            illegal, fraudulent, deceptive and/or suspicious activity, Supply
            Caravan will suspend all Supply Caravan Accounts associated with such
            activity and notify the Users of the reasons for their suspension.
          </li>
          <li>
            <u>Notification to Dwolla</u>. If potentially illegal, fraudulent,
            deceptive and/or suspicious activity is connected with a User’s use of
            Dwolla through the Site, Supply Caravan will notify Dwolla at{' '}
            <a href="mailto:support@dwolla.com">support@dwolla.com</a> of the
            activity detected and provide reasonable information and cooperation
            to Dwolla in connection with its further actions, if any.
          </li>
          <li>
            <u>Reasonable Investigation</u>. Supply Caravan will immediately
            conduct a reasonable investigation to collect facts concerning the
            potentially illegal, fraudulent, deceptive and suspicious activity.
          </li>
          <li>
            <u>
              Permanent Suspension / Account Termination / Notification to Law
              Enforcement
            </u>
            . If Supply Caravan determines that any User(s) are engaging in
            illegal, fraudulent, deceptive and/or suspicious activity while using
            the Marketplace and/or while otherwise using or accessing the Site,
            Supply Caravan may permanently suspend and/or terminate the User(s)’
            Supply Caravan Account, notify appropriate law enforcement, and/or
            pursue any other remedies available at law or equity.
          </li>
        </ol>
      </p>
      <p>
        H. <u>Contracting Disclaimer</u>: PLEASE NOTE THAT SUPPLY CARAVAN CONNECTS
        USERS TO FACILITATE TRANSACTIONS BUT DOES NOT AND CANNOT GUARANTEE THAT A
        USER WILL FULFILL ITS OBLIGATIONS AS REQUIRED BY THE TERMS FOR THE
        TRANSACTION AND/OR APPLICABLE LAW.
      </p>
      <h2 id="x"> X. User Requirements</h2>
      <p>
        Users will be required to accept certain policies and to submit to certain
        background verification requirements from time to time in order to
        register for a Supply Caravan Account and use the full range of
        functionality available through the Site. Each User who registers for a
        Supply Caravan Account and agrees to this Agreement and our{' '}
        <a href="https://www.supplycaravan.com/privacy">Privacy Policy</a>{' '}
        automatically grants Supply Caravan permission to complete any background
        verification requirements (using third parties, as needed) in its
        discretion upon notice to the User.
      </p>
      <h2 id="xi"> XI. Obligations of All Users</h2>
      <p>
        By using the Site, Marketplace and/or Services, you agree to all of the
        following:
      </p>
      <ul>
        <li>
          You will not initiate a Transaction through the Site and subsequently
          seek to interact with a counterparty to that Transaction outside of the
          Site regarding the same or similar transaction involving the Goods.
        </li>
        <li>
          You will immediately remedy any failed payment initiated through your
          Supply Caravan Account and you will not engage in any illegal,
          fraudulent, deceptive and/or suspicious activities while using the Site,
          Marketplace and/or Services.
        </li>
        <li>
          You will not violate the intellectual property rights of Supply Caravan
          or third parties, including, without limitation, patents, trade markets,
          trade names, copyright and design rights and whether registrable,
          registered or unregistered or other rights of any person in any
          jurisdiction in the world;
        </li>
        <li>
          You will not upload viruses, Trojans, worms, time bombs, logic bombs,
          corrupted files or any other malicious code or similar software or
          programs that may damage the operation of the Site, Services or computer
          systems of Supply Caravan;
        </li>
        <li>
          You will not bully, harass or intimidate any User, including, but not
          limited to, the publishing of hate speech, threats, endorsements of
          violence, or pornographic content, such as depictions of nudity or
          graphic violence;
        </li>
        <li>
          You will not use automated methods of information collection on the
          Site, including, but not limited to, scrapers, bots, spiders or framing,
          or deep link to any Supply Caravan Materials, Trademarks, or other
          proprietary information of Supply Caravan or its licensors;
        </li>
        <li>
          You will not copy, decompile, reverse engineer, disassemble, attempt to
          derive the source code, modify, transcribe, store, translate, sell,
          lease, transfer, distribute or create derivative works of the Site,
          Supply Caravan Materials and/or Trademarks, in whole or in part, without
          the prior written approval of Supply Caravan;
        </li>
        <li>
          You will not engage in any action that might disable or impair the Site
          or Services, such as, but not limited to, instigating denial of service
          attacks;
        </li>
        <li>
          You will not use any information contained on the Site for purposes of
          constructing a competing business;
        </li>
        <li>
          You will not provide any false information to the Services, or attempt
          to impersonate any other person, falsify your contact or other
          information, misrepresent a relationship with any person or entity,
          including misrepresenting a relationship with Supply Caravan, or
          otherwise attempt to mislead others as to your identity;
        </li>
        <li>
          You will only create one Supply Caravan Account for yourself or as
          authorized by someone else on his or her behalf;
        </li>
        <li>You will not access any other User’s Supply Caravan Account;</li>
        <li>
          You will not take any action that would undermine the review and rating
          process for the Site and Services;
        </li>
        <li>
          You will not use Services unless you are at least 18 years of age;
        </li>
        <li>You recognize that Services may not always be free of charge;</li>
        <li>
          If you are located in Canada, the United Kingdom or the European Union,
          you consent to having your personal information transmitted to and
          processed in the U.S.;
        </li>
        <li>
          You will not post information, or take any action using the Site and
          Services, that infringes or violates Supply Caravan’s or anyone else’s
          rights, including, but not limited to, privacy rights and intellectual
          property rights; and
        </li>
        <li>
          You will not take any action that would violate, or induce others to
          violate, this Agreement.
        </li>
      </ul>
      <p>
        Supply Caravan may take whatever remedial action it determines in its sole
        discretion is appropriate if you engage in any prohibited acts, including,
        but not limited to, immediate suspension or cancellation of your Supply
        Caravan Account and access to the Site. Furthermore, Supply Caravan
        reserves the right to investigate and take appropriate legal action
        against anyone who, in Supply Caravan’s sole discretion, violates this
        Agreement, including without limitation, reporting such violations to law
        enforcement authorities. You agree that disputes arising from an alleged
        violation of this Agreement or any intellectual property rights may result
        in Supply Caravan suffering irreparable harm and that, in the event of
        such a dispute, Supply Caravan may seek a restraining order, preliminary
        injunctive relief, an injunction, specific performance or other equitable
        relief and/or legal remedies.
      </p>
      <h2 id="xii"> XII. Payment Terms</h2>
      <p>
        The fees charged by Supply Caravan in connection with Transactions are
        published on the Site and may be modified from time to time in Supply
        Caravan’s sole discretion. Unless otherwise indicated, all prices listed
        by Users in an Offer are gross amounts and intended to include all amounts
        potentially applicable to the sale, including but not limited to taxes,
        tariffs, service charges and other transactional fees. The currency
        applicable to the transaction shall rely on the location of Seller; for
        example, the currency shall be U.S. Dollars for all Transactions when the
        Seller is located in the United States of America.
      </p>
      <p>
        Please note that retailers must have a reseller ID to purchase wholesale
        products and must comply with applicable reseller ID requirements, which
        vary from state to state. A reseller ID may also be referred to as a
        seller’s permit, resale certificate or sales tax license (Canada). Users
        are responsible for all applicable taxes in connection with a Transaction
        (except for taxes on Supply Caravan’s income) and Users must obtain proper
        registrations, licenses, approvals and any other documents in connection
        with their business.
      </p>
      <h2 id="xiii"> XIII. ACH Payments</h2>
      <p>
        Supply Caravan has partnered with a third party ACH service provider (“
        <b>ACH Service Provider</b>”) in order to make it possible for Users to
        send and receive payments through the Site. Please see below for
        additional details about these modes of payment.
      </p>
      <p>
        A. <u>ACH Payments</u>. In order to use our white-labeled payment
        functionality powered by Dwolla, Inc. (“<strong>Dwolla</strong>”) and
        integrated into the Site to facilitate ACH payments, you must open a
        &quot;Dwolla Platform&quot; account (a “<strong>Dwolla Account</strong>”)
        provided by Dwolla and you must accept the Dwolla{' '}
        <a href="https://www.supplycaravan.com/terms">Terms of Service</a> and{' '}
        <a href="https://www.supplycaravan.com/privacy">Privacy Policy</a> (the “
        <strong>Dwolla Rules</strong>”). Any funds held in the Dwolla Account are
        held by Dwolla’s financial institution partners as set out in the Dwolla
        Rules. You authorize Supply Caravan to collect and share with Dwolla your
        personal information including full name, date of birth, social security
        number, physical address, email address, and financial information, and
        you are responsible for the accuracy and completeness of that data. You
        understand that you will access and manage your Dwolla Account through the
        Site, and Dwolla Account notifications will be sent by Supply Caravan, not
        Dwolla. We will provide customer support for your Dwolla Account activity,
        and can be reached at{' '}
        <a href="mailto:legal@supplycaravan.com">legal@supplycaravan.com</a>{' '}
        and/or +1 855-566-1389.
      </p>
      <ul>
        <li>
          <p>
            A Dwolla Account may only be created, accessed and managed through the
            Site by Users who are 18 years of age and residents of the United
            States of America. You are responsible for all payment activity
            initiated using your Dwolla Account, including, without limitation,
            any fraudulent activity. If there are Reversals (as defined in the
            Dwolla Rules) of any payments initiated through your Dwolla Account,
            then you will be responsible for compensating Supply Caravan for the
            full amounts of any such Reversals unless the Reversals were made in
            error by Dwolla. Furthermore, if Supply Caravan incurs any losses
            based on unauthorized or erroneous activity initiated by you through
            the Site, whether through misconduct, negligence, error, or otherwise,
            you will be liable to Supply Caravan for such losses.
          </p>
        </li>
        <li>
          <p>
            To set up a Dwolla Account, please note that you must be 18 years of
            age and a resident of the United States of America, and you must
            provide Dwolla with additional information in order to verify your
            bank account. Please refer to Section VII of our{' '}
            <a href="https://www.supplycaravan.com/privacy">Privacy Policy</a> for
            additional information about Dwolla and Dwolla Accounts. Please note
            that by using the payment functionality available to you through your
            Dwolla Account in connection with a Transaction, you are providing
            your express consent to Dwolla’s use of your data (including your
            personal information) for the purpose of providing services to you and
            Supply Caravan.
          </p>
        </li>
      </ul>
      <p>
        You authorize us in connection with your activities through the Site to
        collect and share with our Payment Processors certain personal data and
        transaction information in order to facilitate your Transactions through
        the Site. Our Payment Processors may collect additional personal and other
        information about you as more fully described in our{' '}
        <a href="https://www.supplycaravan.com/privacy">Privacy Policy</a>.
      </p>
      <h2 id="xiv"> XIV. Conduct Within Public Areas of Site</h2>
      <p>
        A. The Services include the Marketplace and may contain other e-mail
        services, bulletin board services, forums, communities or other messaging
        or communication tools designed to enable Users to communicate and
        interact with other Users (collectively, the “
        <strong>Public Areas</strong>”). Users agree to use the Public Areas only
        in compliance with <a href="#xi">Section XI</a> above. Supply Caravan has
        no obligation to monitor the Public Areas; provided, however, Supply
        Caravan reserves the right to review materials prior to publication in
        Public Areas, or to remove any materials already published in Public Areas
        at any time, without notice, for any reason and in our sole discretion.
        Supply Caravan reserves the right to terminate or suspend a User’s access
        to any or all of the Public Areas at any time, without notice, for any
        reason whatsoever. Users acknowledge that Listings and other
        communications posted in the Public Areas by other Users are not
        controlled or endorsed by Supply Caravan in any way. Statements made in
        Listings, forums, bulletin boards and other Public Areas reflect only the
        views of their respective authors. Supply Caravan specifically disclaims
        any liability with regard to the Public Areas and any actions resulting
        from a User’s participation in any Public Areas.
      </p>
      <p>
        B. Users acknowledge and agree that User’s communications with other Users
        via the Public Area or otherwise are public and not private
        communications, and that User has no expectation of privacy concerning
        User’s use of the Public Areas. User acknowledges that personally
        identifiable information that User communicates on the Public Areas may be
        seen and used by others and result in unsolicited communications;
        therefore, SUPPLY CARAVAN STRONGLY ENCOURAGES USERS NOT TO DISCLOSE
        PERSONAL INFORMATION ABOUT THEMSELVES THROUGH THE PUBLIC AREAS. Supply
        Caravan is not responsible for information that Users choose to
        communicate via the Public Areas, or for the actions of other Users.
      </p>
      <h2 id="xv"> XV. Privacy Matters</h2>
      <p>
        We may collect and store personal information about you when you use the
        Site and Services. You acknowledge and agree to our{' '}
        <a href="https://www.supplycaravan.com/privacy">Privacy Policy</a> (as it
        is amended from time to time) which is available at{' '}
        <a href="https://www.supplycaravan.com/terms">
          www.supplycaravan.com/terms
        </a>{' '}
        and to the collection, use and disclosure of personal information in
        accordance with that statement. We will only collect, use, store and/or
        disclose any personal information in accordance with our{' '}
        <a href="https://www.supplycaravan.com/privacy">Privacy Policy</a> and to
        the extent explicitly required by law.
      </p>
      <h2 id="xvi"> XVI. Disputes and Indemnification</h2>
      <p>
        A. You agree that Supply Caravan cannot and does not control or direct our
        Users’ online or offline actions and that we cannot and do not take
        responsibility for User Information and/or any other User Generated
        Content (as defined in the{' '}
        <a href="https://www.supplycaravan.com/privacy">Privacy Policy</a>),
        including but not limited to any critical reviews provided by Users.
        Supply Caravan specifically takes no responsibility for any inaccurate,
        obscene, offensive, unlawful or objectionable User Information or User
        Generated Content.
      </p>
      <p>
        B. You agree to indemnify and hold Supply Caravan harmless from and
        against all damages, losses, and expenses of any kind (including
        reasonable legal fees and costs) related to any claim brought by another
        User or third party against us in connection with the Services used by you
        or in connection with (i) your access, viewing or use of the Site or the
        Services, (ii) any conduct by you that violates the terms of this
        Agreement, (iii) your infringement of the intellectual property rights of
        third parties, (iv) all User Information and User Generated Content
        submitted to the Site and Services, (v) losses or liabilities of Supply
        Caravan related to payments, refunds and/or other payment activity
        initiated and/or facilitated by you through the Site, and (vi) your
        failure to comply with any applicable laws and regulations.
      </p>
      <p>
        C. Supply Caravan shall indemnify and hold Users harmless from and against
        any third party claims related to alleged infringement or wrongful use of
        any patent, trademark, trade secret, copyright or other intellectual
        property rights in connection with the Site. In the event that some or all
        of the Site is held or is reasonably believed by Supply Caravan to
        infringe the rights of a third-party, Supply Caravan shall have the
        option, at its expense, to: (a) modify the Site to make it non-infringing;
        or (b) obtain a license that permits Users to continue using the Site. If
        neither of such options can be exercised by Supply Caravan on a
        commercially reasonable basis and the infringing content or code
        materially affects the Site or the ability of Supply Caravan to meet its
        obligations under this Agreement, then Supply Caravan may terminate this
        Agreement in its discretion. Notwithstanding the foregoing, Supply Caravan
        shall not have any obligation to indemnify Users to the extent that any
        infringement claim arises out of (or is contributed to) by (i) any
        modification of the Site other than by Supply Caravan; or (ii) any
        materials or content provided by Users (including Listings). The foregoing
        states Supply Caravan’s entire liability and exclusive remedy for
        infringement of third-party intellectual property rights.
      </p>
      <p>
        D. No matter where you are located, you agree that any claim, cause of
        action, or controversy associated with Supply Caravan, this Agreement, or
        the Site and/or Services will be governed, without regard to conflict of
        law provisions, by the laws of the United States and the State of New
        Hampshire, and will be subject to the jurisdiction of the U.S. District
        Court or a state court in or around Portsmouth, New Hampshire.
      </p>
      <h2 id="xvii"> XVII. Relationship of Parties</h2>
      <p>
        Supply Caravan does not screen, interview, vet or confirm that any Buyer,
        Seller or Carrier is a legitimate ongoing business, which are the sole
        responsibility of Users. Supply Caravan does not, and cannot, guarantee
        that a User will respond to another User. Users are fully responsible for
        managing their own community profiles, subject to review by Supply Caravan
        in its sole discretion to facilitate the safety and integrity of the Site,
        Services and all Users. PLEASE NOTE THAT SUPPLY CARAVAN IS NOT AND CANNOT
        BE RESPONSIBLE FOR THE ACTIONS OR INACTION OF A USER. ALL USERS
        ACKNOWLEDGE AND AGREE THAT THEY USE THE SITE AND SERVICES AT THEIR OWN
        RISK.
      </p>
      <p>
        Supply Caravan does now own, operate, recommend or endorse any Buyer,
        Seller or Carrier using the Site. Supply Caravan is not responsible or
        liable for any information provided by a User, including but not limited
        to data, advertising, products, goods or services available or unavailable
        from, or through, any User or other third party. You agree that should you
        use or rely on any of the foregoing, Supply Caravan is not responsible or
        liable, indirectly or directly, for any damage or loss caused or alleged
        to be caused by or in connection with such use or reliance. Your dealings
        with, or participation in promotions of any User, and any other terms,
        conditions, representations or warranties associated with such dealings,
        are between you and such User exclusively and do not involve Supply
        Caravan. You should conduct whatever investigation and research you deem
        necessary or appropriate before engaging in Transactions with other Users
      </p>
      <p>
        You agree that Supply Caravan is not responsible for the accessibility or
        unavailability of any User or for your interactions and dealings with
        them, waive the right to bring or assert any claim against Supply Caravan
        relating to any interactions or dealings with any User, and release Supply
        Caravan from any and all liability for or relating to any interactions or
        dealings with other Users.
      </p>
      <h2 id="xviii"> XVIII. Disclaimer of Warranties</h2>
      <p>
        A. <strong>SELLER DESIGNATIONS</strong>. SELLERS MAY HAVE THE OPTION TO
        IDENTIFY THEIR GOODS USING ANY NUMBER OF DESIGNATIONS, SUCH AS “ORGANIC,”
        “CAGE-FREE,” “NON-GMO**” AND OTHERS. SUPPLY CARAVAN MAKES COMMERCIALLY
        REASONABLE EFFORTS TO VERIFY THAT A SELLER MEETS CERTAIN STANDARDS BEFORE
        INDICATING ON THE SITE THAT ITS GOODS SATISFY ANY APPLICABLE CRITERIA OR
        STANDARDS FOR SUCH DESIGNATION; HOWEVER, SUPPLY CARAVAN DOES NOT AND
        CANNOT GUARANTEE THAT ANY GOODS SOLD THROUGH THE SITE BY A SELLER MEETS
        ANY SUCH CRITERIA OR STANDARDS FOR A PARTICULAR DESIGNATION.
      </p>
      <p>
        B. <b>CONTENT OF LISTINGS, RATINGS AND REVIEWS</b>. THE CONTENT OF
        LISTINGS, RATINGS AND REVIEWS RELIES ON DATA FROM SELLERS AND BUYERS AND
        RECEIVED BY SUPPLY CARAVAN IN GOOD FAITH AND ON THE BASIS OF WARRANTIES
        MADE BY THEM; HOWEVER, WE ARE UNABLE TO VERIFY THE ACCURACY OF ANY
        INFORMATION CONTAINED IN LISTINGS, RATINGS OR REVIEWS.
      </p>
      <p>
        C. <b>NEGOTIATIONS AND TRANSACTIONS</b>. YOU AGREE THAT NEGOTIATIONS,
        TRANSACTIONS AND ALL CONTACT BETWEEN YOU AND OTHER USERS OF THE SITE ARE
        CONDUCTED ENTIRELY AT YOUR OWN RISK. IT IS YOUR RESPONSIBILITY TO VERIFY
        ANY INFORMATION FROM OTHER USERS BEFORE RELYING ON IT. YOU AGREE THAT ANY
        PURCHASES, SALES, EXCHANGE OF MONIES AND/OR OF GOODS, OR ANY OTHER
        TRANSACTIONS WILL BE MADE SOLELY IN RELIANCE ON YOUR OWN INQUIRIES AND
        RESEARCH AND THAT SUPPLY CARAVAN HAS NOT MADE ANY WARRANTIES ABOUT THE
        TRUTH AND/OR ACCURACY OF LISTINGS OR OTHER ADVERTISEMENTS, THE
        SUITABILITY, QUALITY, AND/OR EXISTENCE OF GOODS, AND/OR THE ABILITY OF
        USERS TO ACTUALLY ENFORCE OR COMPLETE A TRANSACTION. WE DO NOT WARRANT
        THAT SELLERS ARE THE LEGAL OWNERS OF GOODS MARKETED IN A LISTING.
      </p>
      <p>
        D. <strong>OPERATION AND INTEGRITY OF THE SITE</strong>. YOU RECOGNIZE
        THAT, WHILE WE TRY TO KEEP THE SERVICES BUG-FREE AND SAFE, WE DO NOT
        GUARANTEE THAT THE SERVICES WILL ALWAYS BE SAFE, SECURE OR ERROR-FREE OR
        THAT THE SERVICES WILL ALWAYS FUNCTION WITHOUT DISRUPTIONS, DELAYS OR
        IMPERFECTIONS. YOU ASSUME THE RESPONSIBILITY TO TAKE ADEQUATE PRECAUTIONS
        AGAINST DAMAGES TO YOUR SYSTEMS OR OPERATIONS WHICH COULD BE CAUSED BY
        DEFECTS OR DEFICIENCIES IN THE SITE OR SERVICES. YOU SHALL IMPLEMENT AND
        TAKE RESPONSIBILITY FOR (1) BACKING UP ALL YOUR DATA AND (2) INSTITUTING
        APPROPRIATE REVIEW AND CONFIRMATION PROCEDURES TO VERIFY AND CONFIRM
        TRANSACTIONS THROUGH THE SITE.
      </p>
      <p>
        E. <strong>USE OF SERVICES</strong>. YOUR USE OF THE SERVICES IS AT YOUR
        OWN RISK, AS IS AND AS AVAILABLE WITH ALL FAULTS AND WITHOUT ANY EXPRESS
        OR IMPLIED WARRANTY OF ANY KIND. SUPPLY CARAVAN, ITS LICENSORS, AGENTS AND
        INDEPENDENT CONTRACTORS DISCLAIM ALL WARRANTIES, EXPRESS AND IMPLIED, WITH
        RESPECT TO THE SITE, SUPPLY CARAVAN MATERIAL AND TRADEMARKS, INCLUDING,
        BUT NOT LIMITED TO, WARRANTIES OF ACCURACY, TITLE, QUALITY OF INFORMATION,
        FITNESS FOR A PARTICULAR PURPOSE, MERCHANTABILITY, OR NON-INFRINGEMENT.
      </p>
      <p>
        F. <strong>SUPPLY CARAVAN MATERIAL</strong>. SUPPLY CARAVAN MATERIAL MAY
        CONTAIN INACCURATE OR OUT-OF-DATE INFORMATION OR TYPOGRAPHICAL OR SPELLING
        ERRORS. SUPPLY CARAVAN RESERVES THE RIGHT TO CORRECT ANY ERRORS,
        INACCURACIES, STALENESS OR OMISSIONS IN SUPPLY CARAVAN MATERIAL; HOWEVER,
        SUPPLY CARAVAN UNDERTAKES NO OBLIGATION TO UPDATE, AMEND OR CLARIFY SUPPLY
        CARAVAN MATERIAL, EXCEPT AS REQUIRED BY LAW. NO SPECIFIED UPDATE OR
        FRESHNESS DATE APPLIED TO THE SITE SHOULD BE TAKEN TO INDICATE THAT ALL
        CONTENT HAS BEEN MODIFIED OR UPDATED. SUPPLY CARAVAN DOES NOT MAKE ANY
        REPRESENTATIONS OR WARRANTIES REGARDING: (I) THE TRUTH, ACCURACY,
        COMPLETENESS, TIMELINESS, LEGALITY OR RELIABILITY OF ANY SUPPLY CARAVAN
        MATERIAL, (II) ANY OPINION, INFORMATION, ADVICE OR STATEMENT EXPRESSED BY
        SUPPLY CARAVAN ON THE SITE OR (III) ANY RESULTS TO BE OBTAINED FROM THE
        SERVICES PRESENTED ON THE SITE OR RELYING ON THE SUPPLY CARAVAN MATERIAL.
      </p>
      <p>
        G. <b>THIRD PARTY ACTIONS</b>. YOU AGREE THAT SUPPLY CARAVAN IS NOT
        RESPONSIBLE FOR THIRD PARTIES’ ACTIONS, DATA, CONTENT OR INFORMATION, AND
        YOU HEREBY RELEASE SUPPLY CARAVAN, ITS DIRECTORS AND OFFICERS, AND ITS
        EMPLOYEES AND AGENTS FROM ANY CLAIM OR DAMAGES, KNOWN OR UNKNOWN,
        ASSOCIATED WITH ANY CLAIM THAT MAY ARISE BETWEEN YOU AND ANY THIRD PARTY.
        WE CANNOT AND DO NOT WARRANT OR REPRESENT THAT (I) USERS ARE OVER 18 AND
        OTHERWISE HAVE THE CAPACITY TO ENTER INTO CONTRACTUAL RELATIONS’; (II)
        USERS HAVE THE CAPACITY TO PERFORM THEIR OBLIGATIONS PURSUANT TO THE TERMS
        NEGOTIATED VIA THE SITE; OR (III) ANY STATEMENT MADE BY OR ATTRIBUTED TO A
        USER OF THE SITE IS ACCURATE OR NOT MISLEADING INCLUDING (WITHOUT
        LIMITATION) THE QUANTITY, QUALITY OR TYPE OF GOODS MARKETED.
      </p>
      <p>
        H. <strong>CALIFORNIA RESIDENTS</strong>. IF YOU ARE A CALIFORNIA
        RESIDENT, YOU HEREBY WAIVE CALIFORNIA CIVIL CODE SECTION 1542, WHICH
        STATES, “A GENERAL RELEASE DOES NOT EXTEND TO CLAIMS WHICH THE CREDITOR
        DOES NOT KNOW OR SUSPECT TO EXIST IN HIS FAVOR AT THE TIME OF EXECUTING
        THE RELEASE, WHICH IF KNOWN BY HIM MUST HAVE MATERIALLY AFFECTED HIS
        SETTLEMENT WITH THE DEBTOR.”
      </p>
      <h2 id="xix"> XIX. Limitations on Liability</h2>
      <p>
        A. ANY USER INFORMATION AND SUPPLY CARAVAN MATERIAL (“<b>CONTENT</b>”)
        ACCESSED, DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE USE OF THE SITE AND
        SERVICES IS DONE AT YOUR OWN DISCRETION AND RISK. NO ADVICE OR
        INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM SUPPLY CARAVAN
        OR THROUGH OR FROM THE SITE AND/OR SERVICES SHALL CREATE ANY WARRANTY. YOU
        ACKNOWLEDGE THAT YOU ARE ACCESSING AND VIEWING THE SITE AND THE CONTENT AT
        YOUR OWN RISK. TO THE EXTENT PERMITTED BY APPLICABLE LAW, SUPPLY CARAVAN
        SHALL NOT, UNDER ANY CIRCUMSTANCES, BE RESPONSIBLE FOR ANY DIRECT,
        INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL OR PUNITIVE DAMAGES IN ANY
        WAY ARISING FROM OR RELATING TO YOUR RELIANCE UPON, OR YOUR USE OF OR
        INABILITY TO ACCESS OR VIEW THE SITE OR THE CONTENT, WHETHER SUCH CLAIMS
        ARE BASED IN CONTRACT, TORT OR ANY OTHER LEGAL THEORY, AND WHETHER OR NOT
        SUPPLY CARAVAN IS ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
      </p>
      <p>
        B. YOU ACKNOWLEDGE THAT SUPPLY CARAVAN HAS NO CONTROL OVER, AND NO DUTY TO
        TAKE ANY ACTION REGARDING, WHICH USERS GAIN ACCESS TO OR USE THE SITE AND
        SERVICES, HOW CONTENT AVAILABLE ON THE SITE MAY EFFECT YOU OR YOUR
        DECISIONS, HOW YOU MAY INTERPRET OR USE THE CONTENT ON OR IN CONNECTION
        WITH THE SITE, OR WHAT ACTIONS YOU MAY TAKE AS A RESULT OF HAVING BEEN
        EXPOSED TO THE CONTENT ON OR IN CONNECTION WITH THE SITE AND SERVICES. YOU
        RELEASE SUPPLY CARAVAN FROM ALL LIABILITY FOR YOU HAVING ACQUIRED OR NOT
        ACQUIRED CONTENT OR OTHER INFORMATION THROUGH THE SITE AND SERVICES. THE
        SITE AND SERVICES MAY CONTAIN, OR DIRECT YOU TO SITES CONTAINING,
        INFORMATION THAT SOME PEOPLE MAY FIND OFFENSIVE OR INAPPROPRIATE. SUPPLY
        CARAVAN MAKES NO REPRESENTATIONS CONCERNING ANY CONTENT CONTAINED IN OR
        ACCESSED THROUGH THE SITE AND SERVICES, AND SUPPLY CARAVAN WILL NOT BE
        RESPONSIBLE OR LIABLE FOR THE ACCURACY, COPYRIGHT COMPLIANCE, LEGALITY OR
        DECENCY OF MATERIAL CONTAINED IN OR ACCESSED THROUGH THE SITE AND
        SERVICES.
      </p>
      <p>
        C. SUPPLY CARAVAN ASSUMES NO RESPONSIBILITY OR LIABILITY FOR ANY LOST
        DATA, LOST PROFITS, ERRORS, OMISSIONS, INTERRUPTIONS, DELETIONS, DEFECTS,
        DELAYS IN OPERATION OR TRANSMISSION, COMMUNICATIONS LINE FAILURE, THEFT OR
        DESTRUCTION OR UNAUTHORIZED ACCESS TO, OR ALTERATION OF, THE SITE. SUPPLY
        CARAVAN IS NOT RESPONSIBLE OR LIABLE FOR ANY PROBLEMS OR TECHNICAL
        MALFUNCTION OF ANY TELEPHONE NETWORK OR LINES, COMPUTER ONLINE SYSTEMS,
        SERVERS OR PROVIDERS, COMPUTER EQUIPMENT, MOBILE DEVICES, SOFTWARE OR
        FAILURE OF EMAIL DUE TO TECHNICAL PROBLEMS OR TRAFFIC CONGESTION ON THE
        INTERNET, INCLUDING INJURY OR DAMAGE TO ANY COMPUTER OR MOBILE DEVICE
        RELATED TO OR RESULTING FROM THE SITE.
      </p>
      <p>
        D. YOU AGREE THAT, UNLESS NOT PERMITTED BY APPLICABLE LAW, OUR TOTAL
        LIABILITY ASSOCIATED WITH SUPPLY CARAVAN AND THE SERVICES, INCLUDING, BUT
        NOT LIMITED TO, THIS AGREEMENT, WILL NOT EXCEED THE GREATER OF ONE HUNDRED
        DOLLARS ($100) OR, IF APPLICABLE, THE AMOUNT YOU HAVE PAID US DURING THE
        TWELVE (12) MONTHS PRECEDING THE ACTIONS GIVING RISE TO THE CLAIM.
      </p>
      <p>
        E. APPLICABLE LAW MAY NOT ALLOW THE LIMITATION OR EXCLUSION OF LIABILITY
        OR INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE LIMITATIONS OR
        EXCLUSIONS MAY NOT APPLY TO YOU. IN SUCH CASES, SUPPLY CARAVAN’S LIABILITY
        WILL BE LIMITED TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW.
      </p>
      <h2 id="xx"> XX. Termination or Suspension</h2>
      <p>
        Supply Caravan may terminate or suspend your privilege to use or access
        the Site, Marketplace and/or Services immediately and without notice for
        any reason whatsoever, including without limitation in the event of any
        failed payments and/or your illegal, fraudulent, deceptive and/or
        suspicious activities while using or accessing the Site, Marketplace
        and/or Services. Upon such termination, you must immediately cease
        accessing or using the Site, Marketplace and Services and you agree not to
        access or make use of, or attempt to use, the Site, Marketplace and
        Services under such circumstances. You understand that Supply Caravan may
        exercise this right in its sole discretion, and this right shall be in
        addition to and not in substitution for any other rights and remedies
        available to Supply Caravan.
      </p>
      <h2 id="xxi"> XXI. Force Majeure</h2>
      <p>
        Supply Caravan shall not be in default or otherwise liable for any delay
        in or failure of its performance under this Agreement where such delay or
        failure arises by reason of any Act of God, or any government or any
        governmental body, war, insurrection, acts of terrorism, pandemic, the
        elements, strikes or labor disputes, or other similar or dissimilar causes
        beyond Supply Caravan’s control. Certain obligations may require the
        cooperation of third parties outside the control of Supply Caravan. In the
        event such third parties fail to cooperate in a manner that reasonably
        permits the performance of Supply Caravan’s obligations, such failures
        shall be considered as causes beyond the control of Supply Caravan and
        shall not be the basis for a determination that Supply Caravan is in
        breach of any of its obligations under this Agreement or is otherwise
        liable.
      </p>
      <h2 id="xxii"> XXII. General Provisions</h2>
      <p>
        A. <em>Relationship Between the Parties</em>. This Agreement shall not be
        construed as creating any agency, partnership, joint venture or other
        similar legal relationship between you and Supply Caravan; nor will either
        party hold itself out as an agent, partner or joint venture party of the
        other party.
      </p>
      <p>
        B. <em>Notice</em>. Supply Caravan may direct all notices intended for you
        to your email address provided as part of your Account Credentials upon
        registration for the Site (which you may update from time to time in the
        settings available within the Site). All notices to be delivered to Supply
        Caravan shall be delivered to Supply Caravan using the contact information
        provided below.
      </p>
      <p>
        C. <em>Waiver</em>. No waiver shall be implied from conduct or failure to
        enforce rights. No waiver shall be effective unless in a writing signed by
        all parties to be bound thereto.
      </p>
      <p>
        D. <em>Assignment</em>. Supply Caravan may, at any time, in its sole
        discretion, and without notice to you, assign some or all of its rights
        and obligations under this Agreement. You may not assign your rights or
        delegate your duties under this Agreement without the prior written
        consent of Supply Caravan.
      </p>
      <p>
        E. <em>Third Party Beneficiary Rights</em>. This Agreement does not
        provide for any third party beneficiary rights.
      </p>
      <p>
        F. <em>Entire Agreement and Severability</em>. This Agreement constitutes
        our entire agreement with you in connection with the Site and Services.
        This Agreement supersedes any prior agreements between you and Supply
        Caravan. If any provision of this Agreement is held invalid, illegal or
        unenforceable for any reason, such invalidity, illegality or
        unenforceability shall be deleted and shall not affect any other provision
        of this Agreement.
      </p>
      <h2 id="xxiii"> XXIII. Contact Supply Caravan</h2>
      <p>
        If you have questions or complaints regarding this Agreement, the Site or
        Services, or our business practices, please contact us using the contact
        information below.
      </p>
      <p>
        Address: Supply Caravan, 116 Middle Street, Portsmouth, NH 03801
        <br />
        Phone: +1 855-566-1389
        <br />
        Email:{' '}
        <a href="mailto:legal@supplycaravan.com">legal@supplycaravan.com</a>
      </p>
    </div>
  </Layout>
);

export default TermsPage;
